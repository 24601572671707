import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Header from '../partials/Header';
import SearchListings from '../partials/searchlisting';
import Result from '../partials/resulttemplate';

function SignUp() {
  const [isLoading, setLoading] = useState(true);
  const [results, setResults] = useState();

  let query = "";
  useEffect(() => {
    let listingDataResponse = {};

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let foo = params.get('s');
    foo.replace(/[^\w\s]/gi, '');

    fetch('https://rtrack.social/AUDIOSEARCH?SearchTerm=' + encodeURI(foo))
      .then(response => response.json())
      .then(data => {
        data.map((item, index) => {
          console.log(item, index);
          listingDataResponse["Id" + (index + 1)] = item[0];
          listingDataResponse["Name" + (index + 1)] = item[1];
        });
        listingDataResponse.searchTerm = foo;
        console.log();
        setResults(listingDataResponse);
        setLoading(false);
      });
  }, []);


  if (isLoading) {
    return <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1">Search results for {query}</h1>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <SearchListings/>
              </div>

            </div>
          </div>
        </section>

      </main>

    </div>
  }

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1">Search results for "{results.searchTerm}"</h1>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <Result data={results} />
              </div>

            </div>
          </div>
        </section>

      </main>

    </div>
  );
}

export default SignUp;