import React from 'react'
import ContentLoader from 'react-content-loader'

const ListingWithThumbnail = props => {
  return (
    <ContentLoader 
    speed={2}
    width={1020}
    height={500}
    viewBox="0 0 1020 500"
    backgroundColor="#e8e8e8"
    foregroundColor="#d4d4d4"
    {...props}
  >
    <rect x="51" y="46" rx="3" ry="3" width="422" height="10" /> 
    <rect x="52" y="66" rx="3" ry="3" width="236" height="4" /> 
    <rect x="142" y="-4" rx="3" ry="3" width="291" height="21" /> 
    <circle cx="22" cy="62" r="20" /> 
    <rect x="51" y="97" rx="3" ry="3" width="422" height="10" /> 
    <rect x="52" y="117" rx="3" ry="3" width="236" height="4" /> 
    <circle cx="22" cy="113" r="20" /> 
    <rect x="52" y="146" rx="3" ry="3" width="422" height="10" /> 
    <rect x="53" y="166" rx="3" ry="3" width="236" height="4" /> 
    <circle cx="23" cy="162" r="20" /> 
    <rect x="53" y="192" rx="3" ry="3" width="422" height="10" /> 
    <rect x="54" y="212" rx="3" ry="3" width="236" height="4" /> 
    <circle cx="24" cy="208" r="20" /> 
    <rect x="53" y="243" rx="3" ry="3" width="422" height="10" /> 
    <rect x="54" y="263" rx="3" ry="3" width="236" height="4" /> 
    <circle cx="24" cy="259" r="20" /> 
    <rect x="54" y="292" rx="3" ry="3" width="422" height="10" /> 
    <rect x="55" y="312" rx="3" ry="3" width="236" height="4" /> 
    <circle cx="25" cy="308" r="20" />
  </ContentLoader>
  )
}

ListingWithThumbnail.metadata = {
  name: 'Rituraj ratan',
  github: 'riturajratan',
  description: 'Listing with thumbnail',
  filename: 'ListingWithThumbnail',
}

export default ListingWithThumbnail