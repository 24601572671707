import React from 'react'
import ResultEntry from '../partials/ResultEntry';

const ListingWithThumbnail = props => {
  return (
    <section class="section center-content">
      <div class="container">
          <div class="section-inner">
          
              <div class="tiles-wrap">
                  <ResultEntry name={props.data.Name1} id={props.data.Id1}/>
                  <ResultEntry name={props.data.Name2} id={props.data.Id2}/>
                  <ResultEntry name={props.data.Name3} id={props.data.Id3}/>
                  <ResultEntry name={props.data.Name4} id={props.data.Id4}/>
                  <ResultEntry name={props.data.Name5} id={props.data.Id5}/>
                  <ResultEntry name={props.data.Name6} id={props.data.Id6}/>
                  <ResultEntry name={props.data.Name7} id={props.data.Id7}/>
                  <ResultEntry name={props.data.Name8} id={props.data.Id8}/>
                  <ResultEntry name={props.data.Name9} id={props.data.Id9}/>
                  <ResultEntry name={props.data.Name10} id={props.data.Id10}/>
              </div>
          
          </div>
      </div>
    </section>
  )
}

ListingWithThumbnail.metadata = {
  name: 'Rituraj ratan',
  github: 'riturajratan',
  description: 'Listing with thumbnail',
  filename: 'ListingWithThumbnail',
}

export default ListingWithThumbnail