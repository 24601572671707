const SearchBar = () => (
    <form action="/results" method="get">
        <label htmlFor="header-search">
            <span className="visually-hidden"></span>
        </label>
        <div className="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:mx-0">
            <input
                className="w-full appearance-none bg-gray-800 border border-gray-700 focus:border-gray-600 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-gray-500"
                type="text"
                id="header-search"
                placeholder="Search Roblox Music"
                name="s"
            />
            <button type="submit" className="btn text-white bg-blue-600 hover:bg-blue-700 shadow">Search</button>
        </div>
    </form>
);

export default SearchBar;